@font-face {
  font-family: "sanshiro";
  src: url("fonts/sanshiro-black-webfont.woff2") format("woff2"),
    url("fonts/sanshiro-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#030816" !important;
}

body::-webkit-scrollbar {
  width: 0rem;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

h1 {
  font-family: "sanshiro", sans-serif;
  font-size: 40px;
  color: "#ED6A40";
  letter-spacing: "30px";
}

h2 {
  font-family: "sanshiro", sans-serif;
  font-size: 20px;
}

h3 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

h4 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: "#1F263C";
  text-transform: "none";
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
